import { type UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/vue-query"

import {
  GetStocksParams,
  GetStocksPortfoliosParams,
  StocksService,
} from "../../../../../packages/stocks/composables/services/stocks.service"

type QueryOptions<T> = Partial<UseQueryOptions<T>>

export type GetStockPortfoliosTypes = Awaited<ReturnType<typeof StocksService.getStocksPortfoliosTypes>>
export function useStocksPortfoliosTypes(options: QueryOptions<GetStockPortfoliosTypes> = {}) {
  return useQuery({
    queryKey: ["stocks-portfolios-types"],
    queryFn: ({ signal }) => StocksService.getStocksPortfoliosTypes({ signal }),
    staleTime: Infinity,
    refetchOnReconnect: false,
    placeholderData: arrayInLength(4).map((i: number) => ({ id: String(i) })) as GetStockPortfoliosTypes,
    ...options,
  })
}

export type GetStocksReturnType = Awaited<ReturnType<typeof StocksService.getStocks>>
export function useSNP500Ranking(params?: GetStocksParams, options: QueryOptions<GetStocksReturnType> = {}) {
  return useQuery({
    queryKey: ["snp500-ranking"],
    queryFn: ({ signal }) => StocksService.getStocks({ signal, params }),
    staleTime: Infinity,
    refetchOnReconnect: false,
    placeholderData: {
      data: arrayInLength(3).map((i: number) => ({ id: String(i) })),
    } as GetStocksReturnType,
    ...options,
  })
}

export type GetStockPortfolios = Awaited<ReturnType<typeof StocksService.getStocksPortfoliosYields>>
export const useStocksPortfolioYields = (
  params: ComputedRef<GetStocksPortfoliosParams>,
  options: QueryOptions<GetStockPortfolios> = {}
) => {
  return useQuery({
    queryKey: ["stocks-portfolios", params],
    queryFn: ({ signal }) => StocksService.getStocksPortfoliosYields({ signal, params: params.value }),
    refetchOnReconnect: false,
    placeholderData: keepPreviousData,
    staleTime: Infinity, // 1 day,
    ...options,
  })
}
export type GetStockById = Awaited<ReturnType<typeof StocksService.getStockById>>
export const useStockById = (id: string, options: QueryOptions<GetStockById> = {}) => {
  const { setCurrentStock } = useStocksStore()
  return useQuery({
    queryKey: ["stock", id],
    placeholderData: () => ({ data: arrayInLength(5).map((i) => ({ id: i })) }) as unknown as GetStockById,

    queryFn: async ({ signal }) => {
      const data = await StocksService.getStockById(id, { signal })
      setCurrentStock(data)

      return data
    },
    staleTime: Infinity, // 1 day,
  })
}
